<template>
  <div
    v-if="displayItem"
    class="ui-accordion-item"
    :class="{ 'ui-accordion-item--active': open }"
  >
    <h3
      class="ui-accordion-item__head"
      @click="toggleItem"
    >
      <T3HtmlParser
        class="ui-accordion-item__title"
        :content="item.name"
      />
      <SvgIconArrow class="ui-accordion-item__arrow" />
    </h3>

    <div class="ui-accordion-item__body-wrapper">
      <div class="ui-accordion-item__body">
        <T3HtmlParser
          class="ui-accordion-item__text"
          :content="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import SvgIconArrow from '~ui/assets/new-icons/icon-arrow-accordion.svg'
import type { UiCeCaseStudyBannerTagProps, UiCeProductBoxesItemProps } from '~ui/types'

type ItemProps =
  | Pick<UiCeProductBoxesItemProps, 'id' | 'name' | 'text'>
  | Pick<UiCeCaseStudyBannerTagProps, 'id' | 'name' | 'text'>

const props = defineProps<{
  item: ItemProps
  open: boolean
}>()

const displayItem = computed(() => props.item.name && props.item.text)

const emit = defineEmits<{
  (event: 'toggle', id: null | number): void
}>()

const toggleItem = () => {
  emit('toggle', props.open ? null : props.item.id)
}
</script>

<style lang="scss">
.ui-accordion-item {
  border-bottom: 1px solid var(--color__border-color);

  &:first-child {
    border-top: 1px solid var(--color__border-color);
  }

  $base: &;

  &__head {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 0;
    padding-block: rem(20px);
    gap: rem(24px);
  }

  &__body {
    min-height: 0;

    &-wrapper {
      display: grid;
      align-content: start;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: grid-template-rows 0.2s;

      #{$base}--active & {
        grid-template-rows: 1fr;
      }
    }
  }

  &__title {
    color: var(--color__font-color);
    font-size: rem(22px);
    font-weight: 600;
  }

  &__text {
    color: var(--color__font-color--text);
    font-weight: 300;
    font-size: rem(18px);
    padding-bottom: rem(24px);

    & strong {
      font-weight: 400;
    }

    & p:last-child {
      margin-bottom: 0;
    }

    @include themed-link;
  }

  &__arrow {
    transition: rotate 0.2s;
    transform: rotate(-90deg);
    flex-shrink: 0;
    height: rem(40px);
    width: rem(40px);

    & path {
      width: 100%;
      fill: var(--color__font-color--subheading);
      transition:
        fill 0.2s,
        opacity 0.2s;
    }

    #{$base}--active & {
      rotate: 180deg;

      & path {
        opacity: 0.6;
      }
    }
  }
}
</style>
