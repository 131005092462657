<template>
  <div
    v-if="list.length"
    class="ui-accordion"
    :class="{ 'ui-accordion--with-numbers': withNumbers }"
  >
    <AccordionItem
      v-for="item in list"
      :key="item.id"
      :item="item"
      :open="activeItemId === item.id"
      @toggle="handleAccordionToggle"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { defineQuestion, useSchemaOrg } from '#imports'

import type { UiCeCaseStudyBannerProps, UiCeProductBoxesProps } from '~ui/types'

import AccordionItem from './UiAccordionItem.vue'

type AccordionProps = {
  list: UiCeProductBoxesProps['itemList'] | UiCeCaseStudyBannerProps['tagList']
  withNumbers?: boolean
}

const props = defineProps<AccordionProps>()

const activeItemId = ref<null | number>(props.list?.[0]?.id ?? null)

const handleAccordionToggle = (id: null | number) => {
  activeItemId.value = id
}

const questionSchema = computed(() => {
  return props.list.filter((item) => item.name && item.text)
})

useSchemaOrg([
  ...questionSchema.value.map((question) =>
    defineQuestion({ name: question.name, acceptedAnswer: question.text })
  )
])
</script>

<style lang="scss">
.ui-accordion {
  $base: &;

  &--with-numbers {
    counter-reset: accordion-item-number;

    #{$base}-item {
      counter-increment: accordion-item-number;
    }

    #{$base}-item__title {
      &::before {
        content: counter(accordion-item-number, decimal-leading-zero);
        font-size: rem(18px);
        font-weight: 400;
        text-wrap: nowrap;
        margin-right: rem(32px);
      }
    }
  }
}
</style>
